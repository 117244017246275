<template>
  <InnerScroll :title="$t('system.navigation.account')">
    <AccountForm />
  </InnerScroll>
</template>

<script>
import AccountForm from 'organisms/Forms/Account/Accountform'
import InnerScroll from '../InnerScroll/InnerScroll.vue'

export default {
  name: 'AccountTemplate',
  components: {
    AccountForm,
    InnerScroll
  }
}
</script>

<style lang="scss"></style>
