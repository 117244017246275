<template>
  <div class="e-form e-form--account">
    <el-form
      ref="registerForm"
      :model="registerForm"
      :rules="rules"
      method="post"
    >
      <!-- Errormessages -->
      <el-alert
        v-for="(item, index) in errors.messages"
        :key="index"
        :title="$t(index)"
        type="error"
      >
        <div v-html="$sanitize($t(Array.isArray(item) ? item[0] : item))" />
      </el-alert>
      <!-- /Errormessages -->

      <!-- Successmessage -->
      <el-alert
        v-if="formSuccess"
        :title="$t('messages.user.changeAccountData.success')"
        type="success"
      />
      <!-- /Errormessages -->

      <!-- Row -->
      <el-form-item :label="$t('system.login.username')" prop="username">
        <el-input
          v-model="registerForm.username"
          :placeholder="$t('system.login.username')"
          :disabled="true"
          name="username"
        />
      </el-form-item>

      <!-- Row -->
      <el-form-item :label="$t('system.register.email')" prop="email">
        <el-input
          v-model="registerForm.email"
          :placeholder="$t('system.register.emailPlaceholder')"
          type="email"
          name="email"
        />
      </el-form-item>

      <!-- Row -->
      <el-form-item :label="$t('system.register.gender')" prop="gender">
        <el-select v-model="registerForm.gender">
          <el-option
            v-for="item in genderOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <!-- Row -->
      <el-form-item
        :label="$t('system.register.yearOfBirth')"
        prop="yearOfBirth"
      >
        <el-input
          v-model="registerForm.yearOfBirth"
          type="text"
          placeholder=""
          name="yearOfBirth"
        />
      </el-form-item>

      <!-- Row -->
      <el-form-item
        :label="`${$t('system.register.password')}`"
        class="double-required"
        prop="password"
      >
        <el-input
          ref="password"
          v-model="registerForm.password"
          :placeholder="$t('system.register.password')"
          type="password"
          name="password"
        />
      </el-form-item>

      <!-- Row -->
      <el-form-item
        label=""
        prop="notification"
        class="el-form-item--checkbox el-form-item--nolabel"
      >
        <el-checkbox
          v-model="registerForm.notification"
          :label="$t('system.register.notification')"
          name="notification"
        />
      </el-form-item>

      <!-- Row -->
      <div class="e-form__row e-form__row--submit">
        <el-button
          :loading="loading"
          type="secondary"
          native-type="button"
          class="el-button--delete"
          @click="deleteAccountConfirm"
        >
          <IconTrash />
          <span class="e-mobile-hidden">
            {{ $t('system.deleteAccount') }}
          </span>
        </el-button>

        <el-button
          :loading="loading"
          type="tertiary"
          native-type="submit"
          @click.prevent="submitForm('registerForm')"
        >
          {{ $t('system.refresh') }}
        </el-button>
      </div>

      <div
        class="e-form__help"
        v-html="$sanitize($t('messages.user.changeAccountData.hint'))"
      />
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { userActions } from 'mixins'

// Icons
import IconTrash from 'assets/icons/trashcan.svg'

export default {
  components: {
    IconTrash
  },
  mixins: [userActions],
  data() {
    const validateYear = (rule, value, callback) => {
      if (
        value !== null &&
        value !== '' &&
        (isNaN(parseFloat(value)) || !isFinite(value))
      ) {
        callback(new Error(this.$t('messages.user.yearOfBirth.numOnly')))
      } else if (value !== '' && value > this.maxYear) {
        callback(new Error(this.$t('messages.user.yearOfBirth.noFuture')))
      } else if (value !== '' && value < this.minYear) {
        callback(new Error(this.$t('messages.user.yearOfBirth.error')))
      } else {
        callback()
      }
    }

    return {
      errors: [],
      formSuccess: false,
      loading: false,
      usernameTaken: false,
      usernameFree: false,
      usernameChecking: false,
      genderOptions: [
        {
          value: window.genderEnum.GENDER_FEMALE,
          label: this.$t('system.gender.female')
        },
        {
          value: window.genderEnum.GENDER_MALE,
          label: this.$t('system.gender.male')
        },
        {
          value: window.genderEnum.GENDER_DIVERS,
          label: this.$t('system.gender.divers')
        },
        {
          value: window.genderEnum.GENDER_NONE,
          label: this.$t('system.gender.unknown')
        }
      ],
      registerForm: {
        username: '',
        gender: window.genderEnum.GENDER_NONE,
        yearOfBirth: null,
        password: '',
        email: null,
        notification: false
      },
      rules: {
        email: [
          {
            required: false,
            message: this.$t('messages.user.email.invalid')
          }
        ],
        password: [
          {
            required: true,
            message: this.$t('messages.user.password.empty')
          }
        ],
        yearOfBirth: [
          {
            required: false,
            validator: validateYear,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    minYear: () => 1900,
    maxYear: () => new Date().getFullYear() - 3
  },
  mounted() {
    this.resetFields('registerForm')
  },
  methods: {
    deleteAccountConfirm() {
      this.$confirm(
        this.$t('messages.user.remove.confirm'),
        this.$t('system.warning'),
        {
          confirmButtonText: this.$t('system.confirm'),
          cancelButtonText: this.$t('system.cancel'),
          type: 'warning'
        }
      ).then(async () => {
        await this.__user__deleteAccount()
        this.__user__logout()
      })
    },

    submitForm(formName) {
      if (this.$refs[formName]) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.sendForm(formName)
          } else {
            return false
          }
        })
      }
    },

    async sendForm(formName) {
      this.loading = true
      this.formSuccess = false
      this.errors = []

      try {
        const response = await this.__user__changeUserData(this.registerForm)

        if (response.status === 200) {
          this.formSuccess = true
        }
      } catch (e) {
        this.errors = e
      } finally {
        this.loading = false
        this.resetFields(formName)
      }
    },

    resetFields(formName) {
      this.$refs[formName].resetFields()
      this.registerForm.username = this.userInfo.username
      this.registerForm.gender =
        this.userInfo.gender !== null ? parseInt(this.userInfo.gender) : null
      this.registerForm.yearOfBirth = this.userInfo.yearOfBirth
        ? this.userInfo.yearOfBirth
        : ''
      this.registerForm.notification = this.userInfo.notification
        ? this.userInfo.notification
        : false
      this.registerForm.email = this.userInfo.mailadress
    }
  }
}
</script>
